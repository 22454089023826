<template>
  <b-form-group class="pt-0"
                :label="showLabel ? label : ''"
  >
    <treeselect v-model="currentOrganization"
                :multiple="multiple" :options="items" class=""
                placeholder="Selecione uma unidade"
                :default-expand-level="1"
                name="ou"
    />
  </b-form-group>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'OrganizationSelect',
  components: {
    Treeselect,
  },
  props: {
    value: {
      type: [Object, String, Array],
      required: false,
      default: () => '',
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    client: {
      type: String,
      required: false,
      default: '',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Unidade Organizacional',
    },
  },
  data() {
    return {
      items: [],
      currentOrganization: null,
    }
  },
  watch: {
    currentOrganization(val) {
      this.$emit('input', val)
    },
    client(value) {
      if (value !== '') {
        this.getOrganizations()
      }
    },
    async value(val) {
      await this.getOrganizations()
      this.currentOrganization = val

      if (!val) {
        this.currentOrganization = null
      }

    },
  },
  async mounted() {
    this.currentOrganization = this.value.uuid || ''
    await this.getOrganizations()
  },
  methods: {
    async getOrganizations() {
      let url = 'organization_units'
      if (this.client !== '') {
        url = `clients/${this.client}/organization-units?treeview=true`
      }
      const response = await this.$http.get(url)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }

      this.items = response.map(item => ({ id: item.uuid, label: item.path, children: item.children }))
    },
  },
}
</script>

<style >
.vue-treeselect__control {
  border: 1px solid #CCC !important;
}
</style>
